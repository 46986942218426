import React from 'react'
import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import AudioIcon from './graphics/AudioIcon'
import ReadIcon from './graphics/ReadIcon'
import VideoIcon from './graphics/VideoIcon'
import MixedIcon from './graphics/MixedMediaIcon'

const EyebrowWithType = ({ type, mb }) => {
  const typeSelector = () => {
    switch (type) {
      case 'listen':
        return { IconComponent: AudioIcon, text: 'Listen' }
      case 'read':
      case 'series':
        return { IconComponent: ReadIcon, text: 'Read' }
      case 'watch':
        return { IconComponent: VideoIcon, text: 'Watch' }
      default:
        return { IconComponent: MixedIcon, text: 'Mixed' }
    }
  }

  const { IconComponent, text } = typeSelector()

  return (
    <Box display="flex" alignItems="center" mb={mb}>
      <Box mr={1}>
        <IconComponent />
      </Box>
      <Box>
        <Typography variant="h6" component="p">{text}</Typography>
      </Box>
    </Box>
  )
}

EyebrowWithType.propTypes = {
  type: PropTypes.oneOf(['listen', 'read', 'watch', 'mixed', 'series'])
    .isRequired,
  mb: PropTypes.number,
}
EyebrowWithType.defaultProps = {
  mb: 0,
}

export default EyebrowWithType

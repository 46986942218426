import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles({
  logo: {
    display: 'block',
    height: '100%',
    width: '100%',
    maxWidth: '36px',
    maxHeight: '28px',
  },
})

const ReadIcon = () => {
  const {
    logo,
  } = useStyles()

  return (
    <>
      <Hidden xsUp><p>Read</p></Hidden>
      <SvgIcon className={logo} fontSize="large" viewBox="0 0 36 28">
        <rect width="36" height="28" rx="14" fill="#40A36D" />
        <rect x="10" y="7" width="16" height="1.5" rx="0.75" fill="white" />
        <rect x="10" y="11" width="16" height="1.5" rx="0.75" fill="white" />
        <rect x="10" y="15" width="16" height="1.5" rx="0.75" fill="white" />
        <rect x="10" y="19" width="12.5" height="1.5" rx="0.75" fill="white" />
      </SvgIcon>
    </>
  )
}

export default ReadIcon

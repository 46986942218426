import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Box, Typography, makeStyles, withTheme, Container,
} from '@material-ui/core'
import makeTexture from './graphics/Texture'

import Circle from './graphics/Circle'
import Image from './PreviewCompatibleImage'
import RiseButton from './RiseButton'
import Triangle from './graphics/Triangle'

const useStyles = makeStyles((theme) => ({
  main: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      maxHeight: '600px',
      justifyContent: 'space-between',
    },
  },
  imageContainer: {
    maxWidth: '346px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '500px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
    },
  },
  textContainer: {
    width: '100%',
    padding: '0px 20px',
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      marginBottom: '0px',
      background: 'transparent',
    },
  },
  innerText: {
    maxWidth: '550px',
    width: '100%',
    margin: '0 auto',
  },
  sectionOneTitle: {
    fontWeight: '900',
    fontSize: 32,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      fontSize: 60,
      marginRight: 60,
    },
  },
  markerFont: {
    fontFamily: 'permanent_markerregular',
  },
}))

const splitWord = (str) => {
  const words = str.split(' ')
  const start = words.slice(0, 1).join(' ')
  const end = words.slice(1, words.length).join(' ')
  return { start, end }
}

const aboutBlockQuery = graphql`
  query aboutBlock {
    data: markdownRemark(fields: { slug: { eq: "/pages/about/" } }) {
      frontmatter {
        mainImageFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        paragraphOneTitle
        paragraphOneAText
        paragraphOneBText
        paragraphOneCText
      }
    }
  }
`

const BaseAboutBlock = ({ texture, theme }) => {
  const classes = useStyles()
  const Texture = makeTexture(texture)

  return (
    <StaticQuery
      query={aboutBlockQuery}
      render={({
        data: {
          frontmatter: {
            mainImageFile,
            // mainImageAltText,
            paragraphOneTitle,
            paragraphOneAText,
            // paragraphOneBText,
            // paragraphOneCText,
          },
        },
      }) => {
        const paragraphOneTitleWords = splitWord(paragraphOneTitle)
        return (
          <Container maxWidth="xl" style={{ overflow: 'hidden' }}>
            <Box
              display="flex"
              alignItems="center"
              overflow="visible"
              position="relative"
              className={classes.main}
              pt={3}
            >
              {texture ? (
                <>
                  <Box
                    position="absolute"
                    bottom="-50%"
                    left="50%"
                    width="50%"
                    maxWidth="743px"
                    style={{ transform: 'translateX(-50%)', pointerEvents: 'none' }}
                    zIndex="10"
                  >
                    <Texture variation="grey" />
                  </Box>
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    width="50%"
                    maxWidth="743px"
                    zIndex="10"
                    style={{ pointerEvents: 'none' }}
                  >
                    <Texture variation="grey" />
                  </Box>
                  <Box
                    position="absolute"
                    bottom="0"
                    left="-21%"
                    width="50%"
                    zIndex="10"
                    maxWidth="743px"
                    style={{ pointerEvents: 'none' }}
                  >
                    <Texture variation="blue" />
                  </Box>
                </>
              ) : null}
              <Box
                display="flex"
                width={{ xs: '100%', md: '50%' }}
                position="relative"
                zIndex="-1"
                className={classes.imageContainer}
              >
                <Image imageInfo={mainImageFile} style={{ width: '100%' }} />
                <Box
                  position="absolute"
                  bottom={{ xs: '-1em', md: 0 }}
                  right={{ xs: '-4em', md: '-5em' }}
                  opacity="0.5"
                  maxWidth={{
                    xs: 186,
                    md: '50%',
                  }}
                  width="100%"
                >
                  <Circle color={theme.palette.primary.main} />
                </Box>
                <Box
                  position="absolute"
                  top={{ xs: '-1em', md: 0 }}
                  left={{ xs: '-4em', md: '-5em' }}
                  style={{ opacity: 0.5 }}
                  maxWidth={{
                    xs: 186,
                    md: '50%',
                  }}
                  width="100%"
                >
                  <Triangle mixBlendMode="normal" color={theme.palette.primary.tertiary} />
                </Box>
              </Box>
              <Box className={classes.textContainer}>
                <Box
                  display="flex"
                  flexDirection="column"
                  mr={{ md: 10, lg: 12 }}
                  ml={{ md: 'auto' }}
                  className={classes.innerText}
                >
                  <Typography variant="h2" className={classes.sectionOneTitle}>
                    {paragraphOneTitleWords.start}
                    {' '}
                    <span className={classes.markerFont}>
                      {paragraphOneTitleWords.end}
                    </span>
                  </Typography>
                  <Box mb={2}>
                    <Typography variant="body2">{paragraphOneAText}</Typography>
                  </Box>
                  {/* <Box mb={2}>
                  <Typography variant="body1">{paragraphOneBText}</Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {paragraphOneCText}
                  </Typography>
                </Box> */}
                  <RiseButton type="watch" to="/about">
                    Learn More
                  </RiseButton>
                </Box>
              </Box>
            </Box>
          </Container>
        )
      }}
    />
  )
}

BaseAboutBlock.propTypes = {
  texture: PropTypes.shape({
    blue: PropTypes.shape({}),
    green: PropTypes.shape({}),
    grey: PropTypes.shape({}),
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string,
        tertiary: PropTypes.string,
      }),
    }),
  }).isRequired,
}

BaseAboutBlock.defaultProps = {
  texture: null,
}

export default withTheme(BaseAboutBlock)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles({
  mixedMediaIcon: {
    display: 'block',
    height: '100%',
    width: '100%',
    maxWidth: '36px',
    maxHeight: '28px',
  },
})

const MixedMediaIcon = () => {
  const { mixedMediaIcon } = useStyles()

  return (
    <>
      <Hidden xsUp>
        <p>Mixed Media</p>
      </Hidden>
      <SvgIcon className={mixedMediaIcon} fontSize="large" viewBox="0 0 36 28">
        <rect width="36" height="28" rx="14" fill="#6630DA" />
        <path
          d="M25.8478 7.35489H9.99597C9.58569 7.35489 9.25 7.02191 9.25 6.61494C9.25 6.20797 9.58569 5.875 9.99597 5.875H25.8478C26.2581 5.875 26.5938 6.20797 26.5938 6.61494C26.5938 7.02191 26.2581 7.35489 25.8478 7.35489Z"
          fill="white"
        />
        <path
          d="M18.0151 14.0146H9.99597C9.58569 14.0146 9.25 13.6816 9.25 13.2746C9.25 12.8676 9.58569 12.5347 9.99597 12.5347H18.0151C18.4254 12.5347 18.7611 12.8676 18.7611 13.2746C18.7611 13.6816 18.4254 14.0146 18.0151 14.0146Z"
          fill="white"
        />
        <path
          d="M25.934 20.3039H18.3472C17.9844 20.3039 17.6875 19.9709 17.6875 19.5639C17.6875 19.1569 17.9844 18.824 18.3472 18.824H25.934C26.2969 18.824 26.5937 19.1569 26.5937 19.5639C26.5937 19.9709 26.2969 20.3039 25.934 20.3039Z"
          fill="white"
        />
        <path
          d="M25.8665 12.4605L22.0248 10.2592C21.6704 10.0557 21.2415 10.3147 21.2415 10.7032V15.1058C21.2415 15.5128 21.6704 15.7533 22.0248 15.5498L25.8665 13.3484C26.2208 13.145 26.2208 12.664 25.8665 12.4605Z"
          fill="white"
        />
        <path
          d="M12.2339 21.9686C13.8818 21.9686 15.2177 20.6435 15.2177 19.0088C15.2177 17.3742 13.8818 16.0491 12.2339 16.0491C10.5859 16.0491 9.25 17.3742 9.25 19.0088C9.25 20.6435 10.5859 21.9686 12.2339 21.9686Z"
          fill="white"
        />
      </SvgIcon>
    </>
  )
}

export default MixedMediaIcon

import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  logo: {
    display: 'block',
    height: '100%',
    width: '100%',
    maxWidth: '36px',
    maxHeight: '28px',
  },
})

const VideoIcon = () => {
  const { logo } = useStyles()

  return (
    <>
      <Hidden xsUp>
        <p>Video</p>
      </Hidden>
      <SvgIcon className={logo} viewBox="0 0 36 28">
        <rect width="36" height="28" rx="14" fill="#FF5B16" />
        <path
          d="M26.3077 6.5H9.69231C9.27692 6.5 9 6.77273 9 7.18182V20.8182C9 21.2273 9.27692 21.5 9.69231 21.5H26.3077C26.7231 21.5 27 21.2273 27 20.8182V7.18182C27 6.77273 26.7231 6.5 26.3077 6.5ZM10.3846 20.1364V7.86364H25.6154V20.1364H10.3846Z"
          fill="white"
        />
        <path
          d="M21.1151 13.3863L16.2689 10.659C16.1305 10.5908 16.0612 10.5908 15.9228 10.5908C15.5074 10.5908 15.2305 10.8635 15.2305 11.2726V16.7272C15.2305 17.1363 15.5074 17.409 15.9228 17.409C16.0612 17.409 16.1305 17.409 16.2689 17.3408L21.1151 14.6135C21.2535 14.5454 21.3228 14.4772 21.392 14.3408C21.5305 13.9999 21.4612 13.5908 21.1151 13.3863ZM16.6151 15.5681V12.4317L19.3843 13.9999L16.6151 15.5681Z"
          fill="white"
        />
      </SvgIcon>
    </>
  )
}
export default VideoIcon

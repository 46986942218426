import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles({
  audioIcon: {
    display: 'block',
    height: '100%',
    width: '100%',
    maxWidth: '36px',
    maxHeight: '28px',
  },
})

const AudioIcon = () => {
  const {
    audioIcon,
  } = useStyles()

  return (
    <>
      <Hidden xsUp><p>Audio</p></Hidden>
      <SvgIcon className={audioIcon} fontSize="large" viewBox="0 0 36 28">
        <rect width="36" height="28" rx="14" fill="#307EDA" />
        <path d="M26.624 14.6144C26.624 12.338 25.717 10.1549 24.1025 8.54521C22.4881 6.93555 20.2984 6.03125 18.0151 6.03125C15.7319 6.03125 13.5422 6.93555 11.9277 8.54521C10.3133 10.1549 9.40625 12.338 9.40625 14.6144V21.481C9.40625 21.6328 9.46672 21.7783 9.57435 21.8856C9.68198 21.9929 9.82796 22.0532 9.98018 22.0532H12.8498C13.002 22.0532 13.148 21.9929 13.2556 21.8856C13.3633 21.7783 13.4237 21.6328 13.4237 21.481V15.7589C13.4237 15.6071 13.3633 15.4616 13.2556 15.3542C13.148 15.2469 13.002 15.1867 12.8498 15.1867H10.5541V14.6144C10.5541 12.6416 11.3402 10.7495 12.7394 9.35444C14.1386 7.9594 16.0363 7.17568 18.0151 7.17568C19.9939 7.17568 21.8917 7.9594 23.2909 9.35444C24.6901 10.7495 25.4762 12.6416 25.4762 14.6144V15.1867H23.1805C23.0283 15.1867 22.8823 15.2469 22.7746 15.3542C22.667 15.4616 22.6065 15.6071 22.6065 15.7589V21.481C22.6065 21.6328 22.667 21.7783 22.7746 21.8856C22.8823 21.9929 23.0283 22.0532 23.1805 22.0532H26.0501C26.2023 22.0532 26.3483 21.9929 26.4559 21.8856C26.5636 21.7783 26.624 21.6328 26.624 21.481V14.6144ZM12.2759 16.3311V20.9088H10.5541V16.3311H12.2759ZM23.7544 20.9088V16.3311H25.4762V20.9088H23.7544Z" fill="white" />
      </SvgIcon>
    </>
  )
}

export default AudioIcon

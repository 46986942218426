import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import trackLinkClick from '../utils/trackLinkClick'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: '25px',
    fontFamily: 'inherit',
    padding: '12px 32px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '120%',
    position: 'relative',
    textDecoration: 'none',
    transition: 'color .2s ease, background-color .2s ease',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 24px',
    },
    '&:hover': {
      backgroundColor: '#fff',
      '&$series': {
        color: theme.palette.primary.tertiary,
        '&::after': {
          backgroundColor: theme.palette.secondary.tertiary,
        },
      },
      '&$listen': {
        color: theme.palette.primary.main,
        '&::after': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      '&$watch': {
        color: theme.palette.primary.secondary,
        '&::after': {
          backgroundColor: theme.palette.secondary.secondary,
        },
      },
      '&$read': {
        color: theme.palette.primary.tertiary,
        '&::after': {
          backgroundColor: theme.palette.secondary.tertiary,
        },
      },
      '&$mixed': {
        color: theme.palette.primary.mixed,
        '&::after': {
          backgroundColor: theme.palette.secondary.mixed,
        },
      },
    },
    '&::after': {
      borderRadius: '25px',
      content: '""',
      display: 'block',
      height: '100%',
      left: '4px',
      position: 'absolute',
      top: '4px',
      transition: 'background-color .2s ease',
      width: '100%',
      zIndex: '-1',
    },
    '&$flat': {
      backgroundColor: 'transparent',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#000',
      },
    },
  },
  series: {
    '&::after': {
      backgroundColor: theme.palette.primary.tertiary,
    },
  },
  listen: {
    '&::after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  watch: {
    '&::after': {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  read: {
    '&::after': {
      backgroundColor: theme.palette.primary.tertiary,
    },
  },
  mixed: {
    '&::after': {
      backgroundColor: theme.palette.primary.mixed,
    },
  },
  flat: {},
}))

const RiseButton = ({
  children,
  type,
  to = null,
  flat = false,
  onClick = null,
  startIcon,
  fullWidth = false,
  disabled = false,
  linkId = null,
  linkClass = null,
  buttonId = null,
  buttonClass = null,
  target = '_self',
}) => {
  const classes = useStyles()

  return to ? (
    <TransitionLink
      exit={{
        length: 0.4,
        delay: 0,
      }}
      entry={{
        delay: 0.4,
        length: 1,
      }}
      to={to}
      style={{ textDecoration: 'none' }}
      id={linkId}
      className={linkClass}
      target={target}
      onClick={trackLinkClick}
    >
      <Button
        className={[
          classes.root,
          buttonClass,
          flat ? classes.flat : classes[type],
        ].join(' ')}
        startIcon={startIcon}
        fullWidth={fullWidth}
        disabled={disabled}
        id={buttonId}
      >
        {children}
      </Button>
    </TransitionLink>
  ) : (
    <Button
      onClick={onClick}
      className={[
        classes.root,
        buttonClass,
        flat ? classes.flat : classes[type],
      ].join(' ')}
      startIcon={startIcon}
      fullWidth={fullWidth}
      disabled={disabled}
      id={buttonId}
    >
      {children}
    </Button>
  )
}

RiseButton.defaultProps = {
  target: '_self',
  to: null,
  type: null,
  flat: false,
  onClick: null,
  startIcon: null,
  fullWidth: false,
  disabled: false,
  buttonId: null,
  buttonClass: null,
  linkId: null,
  linkClass: null,
}

RiseButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  target: PropTypes.string,
  type: PropTypes.oneOf(['listen', 'read', 'watch', 'mixed', 'series']),
  to: PropTypes.string,
  flat: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  linkId: PropTypes.string,
  linkClass: PropTypes.string,
  buttonId: PropTypes.string,
  buttonClass: PropTypes.string,
}

export default RiseButton

import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { TweenLite } from 'gsap'
import { IS_FIREFOX } from '../utils/constants'

const useStyles = makeStyles((theme) => ({
  borderRadius: {
    borderRadius: '30px',
    overflow: 'hidden',
    transform: 'translateZ(0)',
  },
  active: {
    '& $shift': {
      transform: 'translate(-10px,-10px)',
    },
    '& $shiftHidden': {
      height: '100%',
      transform: 'translate(10px,10px)',
      width: '100%',
    },
    '& $imageContainer': {
      transform: 'scale(1.2)',
    },
  },
  shift: {
    transition: 'transform .3s ease',
    height: '100%',
    transform: 'translate(10px,10px)',
    width: '100%',
  },
  shiftHidden: {
    transition:
      'transform .3s ease, left .3s ease, height .3s ease, width .3s ease',
    height: '99%',
    transform: 'translate(0px,0px)',
    width: '99%',
  },
  listen: {
    backgroundColor: theme.palette.primary.main,
    '&$shiftHidden': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  watch: {
    backgroundColor: theme.palette.primary.secondary,
    '&$shiftHidden': {
      backgroundColor: theme.palette.secondary.secondary,
    },
  },
  read: {
    backgroundColor: theme.palette.primary.tertiary,
    '&$shiftHidden': {
      backgroundColor: theme.palette.secondary.tertiary,
    },
  },
  mixed: {
    backgroundColor: theme.palette.primary.mixed,
    '&$shiftHidden': {
      backgroundColor: theme.palette.secondary.mixed,
    },
  },
  imageContainer: {
    transition: 'transform .3s ease',
    transform: 'scale(1.04)',
  },
}))

const formatImageUrl = (url) => url.split('/static')[1]

const Thumbnail = ({ image, type, active }) => {
  const classes = useStyles()
  const displacementRef = useRef(null)
  const randId = useState(Math.random())
  // TODO ENFORCE 16:9 aspect ratio!
  // const correctAspectRatio = Math.abs(image.childImageSharp.fluid.aspectRatio - 1.7) < 0.1
  // if (!correctAspectRatio) throw new Error('Aspect error is not 16:9')
  const hasFilter = !IS_FIREFOX
  useEffect(() => {
    if (active && hasFilter) {
      TweenLite.to(displacementRef.current, 0.2, {
        attr: { baseFrequency: '0.00001, 0.4' },
      })
      TweenLite.to(displacementRef.current, 0.2, {
        attr: { baseFrequency: '0.00001, 0.00001' },
        delay: 0.2,
      })
    }
  }, [active])
  const filterId = `thumbnail-filter-${randId[0]}`
  return (
    <Box
      position="relative"
      mb={1.25}
      mr={1.25}
      className={active ? classes.active : null}
    >
      <Box
        position="absolute"
        className={[classes.borderRadius, classes.shift, classes[type]].join(
          ' ',
        )}
      />
      <Box
        position="absolute"
        className={[
          classes.borderRadius,
          classes.shiftHidden,
          classes[type],
        ].join(' ')}
      />
      <Box position="relative" className={[classes.borderRadius].join(' ')}>
        <Box className={classes.imageContainer}>
          <svg
            className="distort"
            width="100%"
            height="100%"
            viewBox="0 0 720 405"
            style={{ borderRadius: '30px' }}
          >
            {hasFilter && (
            <filter id={filterId}>
              <feTurbulence
                ref={displacementRef}
                type="fractalNoise"
                baseFrequency="0.00001 0.00001"
                result="NOISE"
                numOctaves="2"
              />
              <feDisplacementMap
                in="SourceGraphic"
                in2="NOISE"
                scale="30"
                xChannelSelector="R"
                yChannelSelector="G"
              />
            </filter>
            )}
            <g
              filter={hasFilter ? `url(#${filterId})` : ''}
            >
              <image
                className="distort__img"
                xlinkHref={typeof image === 'object' ? image?.childImageSharp.fluid.src : formatImageUrl(image)}
                height="406"
                width="720"
              />
            </g>
          </svg>
        </Box>
      </Box>
    </Box>
  )
}

Thumbnail.defaultProps = {
  active: false,
}

Thumbnail.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
      }),
    }),
  }).isRequired,
  type: PropTypes.oneOf(['listen', 'read', 'watch', 'mixed']).isRequired,
  active: PropTypes.bool,
}

export default Thumbnail
